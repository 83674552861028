import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MaisNoticias = ({ data }) => {
  const noticias = data.allGhostPost.edges

  return (
    <div className="col">
      <h2>
        <strong>
          <small className="text-danger">
            <i>
              {/* <FontAwesomeIcon icon={["fas", "plus"]} /> */}
            </i>
          </small>{" "}
          notícias
        </strong>
      </h2>
      <div className="col-12 mt-4 ">
        {noticias.map(({ node }) => {
          return (
            <div key={node.slug} className="p-2 ">
              <Link to={`/noticias/${node.slug}`}>
                <h5 className="text-left">{node.title}</h5>
              </Link>
              <hr className="w-100" />
            </div>
          )
        })}
        <Link className="text-reset " to="/noticias">
          <p className="font-weight-bold text-center text-danger">
            <strong>mais notícias</strong>{" "}
          </p>
        </Link>
      </div>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query MaisNoticiasQuery {
        allGhostPost(
          sort: { order: DESC, fields: [published_at] }
          limit: 5
          skip: 0
          filter: {
            slug: { ne: "data-schema" }
            featured: { eq: false }
            tags: { elemMatch: { slug: { eq: "noticias" } } }
          }
        ) {
          edges {
            node {
              ...GhostPostFields
            }
          }
        }
      }
    `}
    render={data => <MaisNoticias data={data} {...props} />}
  />
)
