import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const NoticiasPrincipaisCarousel = ({ data }) => {
  const noticias = data.allGhostPost.edges

  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-ride="carousel"
    >
      <div className="carousel-inner">
        {noticias.map(({ node }, index) => (
          <div
            key={index}
            className={"carousel-item " + (index === 0 ? "active" : "")}
          >
            <div className="row pb-2">
              <div className="col-lg-6">
                <Link to={`/noticias/${node.slug}`}>
                  <img
                    src={node.feature_image}
                    className="img-fluid"
                    alt={node.title}
                  />
                </Link>
              </div>
              <div className="col-lg-6 pt-2">
                <h6 className="tag-title">
                  {node.tags[1] ? node.tags[1].name : node.tags[0].name}
                </h6>

                <div className="">
                  <Link to={`/noticias/${node.slug}`}>
                    <h4>
                      {/* {node.title.length >= 65 ? node.title.substr(0, 500) + '...' : node.title} */}
                      {node.title}
                    </h4>
                  </Link>
                  <Link to={`/noticias/${node.slug}`}>
                    {node.custom_excerpt ? (
                      <p>
                        <strong>{node.custom_excerpt}</strong>
                      </p>
                    ) : (
                      <p>
                        {node.excerpt
                          .replace(/ *\[[^)]*\] */g, "")
                          .substr(0, 270) + "..."}
                      </p>
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <a
        className="carousel-control-prev "
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="sr-only  ">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="sr-only text-primary">Next</span>
      </a>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query NoticiasPrincipaisCarouselQuery {
        allGhostPost(
          sort: { order: DESC, fields: [published_at] }
          limit: 3
          filter: {
            featured: { eq: true }
            slug: { ne: "data-schema" }
            tags: { elemMatch: { slug: { eq: "noticias" } } }
          }
        ) {
          edges {
            node {
              ...GhostPostFields
            }
          }
        }
      }
    `}
    render={data => <NoticiasPrincipaisCarousel data={data} {...props} />}
  />
)
