import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Twitter from "../components/twitter"
import {MetaData} from "../components/meta"
import NoticiasPrincipaisCarousel from "../components/noticias-principais-carousel"
import NoticiasPrincipais from "../components/noticias-principais"
import CadastroEmail from "../components/cadastro"
import MaisNoticias from "../components/mais-noticias"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const IndexPage = ({ data,  location,}) => {
  const video = data.allYoutubeVideo.edges[0]

  return (
    <Layout isHome={true}>
       <MetaData location={location} />
      {/* seção do inicio */}
      <div className="row">
        <div className="col-lg-9">
          <div className="row">
            <div className="col">
            <NoticiasPrincipaisCarousel />
            </div>
            
          </div>

          <div className="row pt-3">
            <div className="col">
            <NoticiasPrincipais />
            </div>
            
          </div>

          {/* seção do meio */}
          <div className="row mt-md-4 pt-3">
            <div className="col-sm-6 col-md-6 col-lg-6  bg-light p-4 rounded-lg">
              <MaisNoticias />
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 p-4 rounded-lg ">
              <h2>
                <strong>
                  <small className="text-danger">
                    <i>
                      {/* <FontAwesomeIcon icon={["fab", "youtube"]} /> */}
                    </i>
                  </small>{" "}
                  vídeo
                </strong>
              </h2>
              <div className="col-12 mt-4 ">
                <p className="text-left small strong ">
                  {video.node.publishedAt}
                </p>

                <Link className="" to={`videos/${video.node.slug}`}>
                  <img
                    className="img-fluid"
                    src={video.node.thumbnails.high.url}
                    alt={video.node.title}
                  />
                  <h5 className="text-left font-weight-bold mt-3">
                    {video.node.title}
                  </h5>

                  <p className="mt-3 ">{video.node.description}</p>
                </Link>
                <hr className="w-100" />

                <Link className="text-reset " to="/videos">
                  <p className="font-weight-bold text-center text-danger">
                    <strong>mais vídeos</strong>
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <CadastroEmail />
            </div>
            
          </div>
        </div>
        <div className="col-lg-3">
          <div className="row pt-3 ml-1">
            <div className="col">
              <h2>
                <strong>
                  <small className="text-danger">
                    <i>
                      {/* <FontAwesomeIcon icon={["fab", "twitter"]} /> */}
                    </i>
                  </small>{" "}
                  twitter
                </strong>
              </h2>
              <Twitter limitPosts={4} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    allYoutubeVideo(sort: { order: DESC, fields: publishedAt }, limit: 1) {
      edges {
        node {
          ...YoutubeVideoFields
        }
      }
    }
  }
`

export default IndexPage
