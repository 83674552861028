import React from "react"

import PropTypes from "prop-types"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Twitter = ({ limitPosts }) => (
  <a
    className="twitter-timeline"
    data-tweet-limit={limitPosts}
    data-chrome="noheader nofooter"
    href="https://twitter.com/PompeodeMattos?ref_src=twsrc%5Etfw"
  >
    Tweets by PompeodeMattos
  </a>
)
export default Twitter

Twitter.propTypes = {
  limitPosts: PropTypes.number.isRequired,
}
