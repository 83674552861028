import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const NoticiasPrincipais = ({ data }) => {
  const noticias = data.allGhostPost.edges

  return (
    <div className="row">
      {noticias.map(({ node }) => (
        <div
          key={node.slug}
          className="col-sm-6 col-md-6 col-lg-3 border-right"
        >
          <h6 className="tag-title">
            {node.tags[1] ? node.tags[1].name : node.tags[0].name}
          </h6>
          <Link to={`noticias/${node.slug}`}>
            <div className="img-wrapper img-noticias">
              <img
                src={node.feature_image}
                className="img-fluid"
                alt={node.title}
              />
            </div>
          </Link>
          <Link to={`noticias/${node.slug}`}>
            <h6 className="mt-2">
              {/* {node.title.length >= 50 ? node.title.substr(0, 46) + ' ...' : node.title} */}
              {node.title}
            </h6>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query NoticiasPrincipaisQuery {
        allGhostPost(
          sort: { order: DESC, fields: [published_at] }
          limit: 4
          skip: 3
          filter: {
            featured: { eq: true }
            slug: { ne: "data-schema" }
            tags: { elemMatch: { slug: { eq: "noticias" } } }
          }
        ) {
          edges {
            node {
              ...GhostPostFields
            }
          }
        }
      }
    `}
    render={data => <NoticiasPrincipais data={data} {...props} />}
  />
)
