import React from "react"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const CadastroEmail = () => (
  <div className="">
    <div className="row align-items-center bg-principal pt-2 rounded-lg border-white">
      <div className="col-5">
        <h4 className="text-white text-nowrap">#informe-se</h4>
      </div>
      <div className="col-7">
        <p className="text-right text-white small">
          Receba em primeira mão as informações sobre os temas que defendo no
          Congresso
        </p>
      </div>
    </div>
    <div className="row border-white bg-secundario rounded-lg mt-n1">
      <div className="col-10 mx-auto py-4 ">
        <label className="sr-only" htmlFor="inlineFormInputGroupUsername2">
          Email
        </label>
        <div className="input-group mb-2 mr-sm-2 border-2 border-white rounded-lg">
          <input
            type="text"
            className="form-control bg-warning"
            id="inlineFormInputGroupUsername2"
            placeholder="Seu email aqui..."
          />
          <div className="input-group-prepend">
            <button type="submit" className="btn btn-primario mb-2">
              <i className="text-white">
                {/* <FontAwesomeIcon icon={["fas", "arrow-circle-right"]} /> */}
              </i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default CadastroEmail
